// Primary Colors
var Color_Blue0 = "#57b2f4"
var Color_Blue1 = "#2c7be5"
var Color_Blue2 = "#1d6cd6"
var Color_Blue3 = "#1867d1"
var Color_Blue4 = "#0958c2"
var Color_Blue5 = "#517fa4"

var Color_DarkNavy1 = "#151529"
var Color_DarkNavy2 = "#1f1f33"
var Color_DarkNavy3 = "#333347"
var Color_DarkNavy4 = "#333"

var Color_LightNavy1 = "#cbc3e3"
var Color_LightNavy2 = "#F2F4F4"
var Color_LightNavy3 = "#00BCD4"

// Gradient Start Colors
var Color_Gradient_Start1 = "#3c7bba"
var Color_Gradient_Start2 = "#2d6cab"
var Color_Gradient_Start3 = "#2867a6"
var Color_Gradient_Start4 = "#195897"
var Color_Gradient_Start5 = "#194e8f"

// Gradient End Colors
var Color_Gradient_End1 = "#3a4d96"
var Color_Gradient_End2 = "#2b3e87"
var Color_Gradient_End3 = "#263982"
var Color_Gradient_End4 = "#172a73"

// Secondary Colors
// Success Colors
var Color_Success2 = "#00d97e"
var Color_Success3 = "#00ca6f"
var Color_Success4 = "#00c56a"
var Color_Success5 = "#00b65b"

// Warning Colors
var Color_Warning1 = "#f6c343"
var Color_Warning2 = "#e7b434"
var Color_Warning3 = "#e2af2f"
var Color_Warning4 = "#d3a020"
var Color_Warning5 = "#FF9630"

// Error Colors
var Color_Error2 = "#e63757"
var Color_Error3 = "#d72848"
var Color_Error4 = "#d22343"
var Color_Error5 = "#c31434"

// Grey Colors
var Color_Gray1 = "#666666"

// NavyGrey Colors
var Color_NavyGray1 = "#f9fafb"
var Color_NavyGray2 = "#edf2f9"
var Color_NavyGray3 = "#95aac9"
var Color_NavyGray4 = "#6e84a3"
var Color_NavyGray5 = "#3b507b"
var Color_NavyGray6 = "#12263f"

//White Colors
var Color_White1 = "white"

var Color_SeaGreen1 = "#46ffc0"
var Color_SeaGreen2 = "#068F97"
var Color_SeaGreen3 = "#267659"


var Color_LightMustard1 = "#ffc45a"

var Color_RobinSEgg = "#6dffff"
var Color_PowderPink = "#ffade1"

var Color_Purple1 = "#6b5eae"

var Color_AdminBlue= "#DCF9FB"
var Color_AdminBlue2= "#D6F7EB"

var Color_White= "#FFFFFF"
var Color_Blue = "#1DA2AA"

export const Theme = {
  Color_Blue,
  Color_Blue0,
  Color_Blue1,
  Color_Blue2,
  Color_Blue3,
  Color_Blue4,
  Color_Blue5,

  Color_DarkNavy1,
  Color_DarkNavy2,
  Color_DarkNavy3,
  Color_DarkNavy4,

  Color_LightNavy1,
  Color_LightNavy2,
  Color_LightNavy3,

  Color_Gradient_Start1,
  Color_Gradient_Start2,
  Color_Gradient_Start3,
  Color_Gradient_Start4,
  Color_Gradient_Start5,
  Color_Gradient_End1,
  Color_Gradient_End2,
  Color_Gradient_End3,
  Color_Gradient_End4,

  Color_Success2,
  Color_Success3,
  Color_Success4,
  Color_Success5,

  Color_Warning1,
  Color_Warning2,
  Color_Warning3,
  Color_Warning4,
  Color_Warning5,

  Color_Error2,
  Color_Error3,
  Color_Error4,
  Color_Error5,

  Color_Gray1,

  Color_NavyGray1,
  Color_NavyGray2,
  Color_NavyGray3,
  Color_NavyGray4,
  Color_NavyGray5,
  Color_NavyGray6,

  Color_White,
  Color_White1,

  Color_SeaGreen1,
  Color_SeaGreen2,
  Color_SeaGreen3,

  Color_LightMustard1,
  Color_RobinSEgg,
  Color_PowderPink,
  Color_Purple1,

  Color_AdminBlue,
  Color_AdminBlue2
}
