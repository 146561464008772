import React, { useEffect, useState } from "react";
import { UncontrolledDropdown, DropdownToggle } from "reactstrap";
// import PerfectScrollbar from "react-perfect-scrollbar"
import { Button } from "../../../components/custom/button/Button";
// import { Spinner } from "../../../components/custom/spinner/Spinner"
import { useQuery } from "@apollo/client";
import { GET_BY_PATH_MENU } from "../../../graphql/Query.jsx";
import { useLocation } from "react-router-dom";

export const GetModule = (props) => {
  let location = useLocation();

  // const MODULES = JSON.parse(localStorage.getItem("MODULES"))
  let schoolName =
    localStorage.getItem("USER_SCHOOL_NAME") !== "null"
      ? localStorage.getItem("USER_SCHOOL_NAME")
      : "Open Data/API";
  let orgName =
    localStorage.getItem("USER_ORGANIZATION_NAME") !== "null"
      ? localStorage.getItem("USER_ORGANIZATION_NAME")
      : "Open Data/API";

  // const [moduleName, setModuleName] = useState((orgName !== "null")? orgName : schoolName)
  const moduleName = orgName !== "null" ? orgName : schoolName;
  const [moduleIcon, setModuleIcon] = useState(
    orgName !== "null" ? "fas fa-landmark" : "fas fa-school"
  );

  const { data: dataByPath } = useQuery(GET_BY_PATH_MENU, {
    variables: { input: location.pathname },
  });

  useEffect(() => {
    if (dataByPath) {
      if (dataByPath.getByPathMenu.parentMenuLevel1.menuParentCode === "0000") {
        // setModuleName(dataByPath.getByPathMenu.parentMenuLevel1.menuNameTH)
        setModuleIcon(dataByPath.getByPathMenu.parentMenuLevel1.icon);
      } else {
        // setModuleName(dataByPath.getByPathMenu.parentMenuLevel1.parentMenuLevel2.menuNameTH)
        setModuleIcon(
          dataByPath.getByPathMenu.parentMenuLevel1.parentMenuLevel2.icon
        );
      }
    }
  }, [dataByPath]);

  // function handleClick(path) {
  //   window.location = path
  // }

  // function Module() {
  //   return (<>
  //     {!MODULES ? <Spinner color="primary"/>
  //      : MODULES.map((item, key) => (
  //     <div className="d-flex justify-content-between body-list-modules" key={key} >
  //     {/* onClick={()=>handleClick( item.firstRoleMenu.menuPath )} */}
  //       <Media className="d-flex align-items-start">
  //         <Media left href="">
  //           <div style={{ fontSize: 20 , color:'#267659'}} >
  //             <i className={item.icon} />
  //           </div>
  //         </Media>
  //         <Media body>
  //           <Media heading className="media-heading mb-0" style={{color: "#267659" }}  tag="h5">
  //             {item.menuNameTH}
  //           </Media>
  //         </Media>
  //       </Media>
  //     </div>
  //     ))}
  //   </>)
  // }

  return (
    <>
      <UncontrolledDropdown
        tag="li"
        className="dropdown-notification nav-item d-none d-lg-block"
      >
        <DropdownToggle
          tag="a"
          className="nav-link nav-link-label pt-0 pb-0 pl-0 pr-0"
        >
          <Button
            outline
            type="primary"
            // icon={moduleIcon}
            height="40"
            width="300"
            disabled
          >
            {moduleName}
          </Button>
        </DropdownToggle>
        {/* <DropdownMenu tag="ul" className="dropdown-menu-media dropdown-menu-left pt-0 mt-1">
        <li className="dropdown-menu-header">
          <div className="dropdown-header mt-0">
            <h4 className="text-white">เมนูหลัก</h4>
          </div>
        </li>
        <PerfectScrollbar
          className="media-list overflow-hidden position-relative"
          options={{
            wheelPropagation: false,
          }}
        >
          <Module/>
        </PerfectScrollbar>
        <li className="dropdown-menu-footer">
          <DropdownItem tag="a" className="p-1 text-center">
            <span className="align-middle">Bangkok of Education Management Information System</span>
          </DropdownItem>
        </li>
      </DropdownMenu> */}
      </UncontrolledDropdown>
    </>
  );
};
